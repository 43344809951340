import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF } from '@angular/common';
@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css'],
})
export class LanguageSwitcherComponent implements OnInit {
  countryFlag: any = 'flag01.png';
  public lang: string = '/';
  public selectedCountryCode: string = 'fr';
  public countryWithCodes: any = ['en', 'fr'];
  public customLabels: any = {
    en: 'English',
    fr: 'France',
  };
  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    if (this.baseHref === '/') {
      this.cookieService.set('selectedCountryCode', 'fr');
      this.cookieService.set('selectedCountryFlag', this.countryFlag);
      this.countryFlag = 'flag03.png';
    } else {
      let baseHref = this.baseHref.replace(/^\/|\/$/g, '');
      this.cookieService.set('selectedCountryCode', baseHref);
      this.cookieService.set('selectedCountryFlag', this.countryFlag);
      this.countryFlag = 'flag01.png';
    }
  }

  ngOnInit(): void {
    //   let url=window.location.origin+"fr";
    //   let urlLastIndex = this.trimLastSlashFromUrl(url);
    //   const countryCodeExists: boolean = this.cookieService.check('selectedCountryCode');
    //   if(countryCodeExists && this.cookieService.get('selectedCountryCode')!=''){
    //     this.selectedCountryCode=this.cookieService.get('selectedCountryCode');
    //   }else{
    //     this.cookieService.set('selectedCountryCode',this.selectedCountryCode);
    //     //alert(this.countryFlag);
    //   }
    //   const countryFlagExists: boolean = this.cookieService.check('selectedCountryFlag');
    //   if(countryFlagExists && this.cookieService.get('selectedCountryFlag')!=''){
    //     this.countryFlag=this.cookieService.get('selectedCountryFlag');
    //   }else{
    //     this.cookieService.set('selectedCountryFlag',this.countryFlag);
    //     //alert(this.countryFlag);
    //   }
    // this.languageSwitcherAction(this.selectedCountryCode,this.countryFlag);
  }

  trimLastSlashFromUrl(baseUrl: any = '') {
    if (baseUrl[baseUrl.length - 1] == '/') {
      alert(baseUrl);
      let trimmedUrl = baseUrl.substring(0, baseUrl.length - 1);
      return trimmedUrl;
    }
  }

  public languageSwitcherAction(value: string = '', flag: any = ''): void {
    //this.flag_img=flag;
    if (value == '') {
      return;
    }
    let redirectTo = '';
    if (value != '') {
      if (value === 'fr') {
        redirectTo = (window.location.origin + '/' + this.router.url).replace(
          /([^:]\/)\/+/g,
          '$1'
        );
      } else {
        redirectTo = (
          window.location.origin +
          '/' +
          value +
          this.router.url
        ).replace(/([^:]\/)\/+/g, '$1');
      }
      //this.cookieService.set('selectedCountryCode',value);
      // this.cookieService.set('selectedCountryFlag',flag);
    }

    // this.cookieService.putObject('language', {
    //   language: value
    // });
    // this.cookieService.put('lang', value);
    window.location.href = redirectTo;
  }

  public changeSelectedCountryCode(value: string): void {
    // if (value == '') { return; }
    // if (value == this.selectedCountryCode) { return; }
    // this.selectedCountryCode = value;
    // let urlvalue: string = '/';
    // if(value == 'en') {
    //   urlvalue = '/';
    //   let redirectTo = (window.location.origin + urlvalue + this.router.url).replace(/([^:]\/)\/+/g, "$1");
    //   this.cookieService.putObject('language', {
    //     language: urlvalue
    //   });
    //   this.cookieService.put('lang', urlvalue);
    //   window.location.href = redirectTo;
    // } else {
    //   urlvalue = '/ar';
    //   let redirectTo = (window.location.origin + urlvalue + this.router.url).replace(/([^:]\/)\/+/g, "$1");
    //   this.cookieService.putObject('language', {
    //     language: urlvalue
    //   });
    //   this.cookieService.put('lang', urlvalue);
    //   window.location.href = redirectTo;
    // }
  }
}

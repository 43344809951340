import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  headers: any;
  _baseURL: any;
  user: any;
  deviceId: number;
  constructor(
    private httpClient: HttpClient,
    private globaols: Globals
  ) {
    this.deviceId = Date.now();
    this.user = this.globaols.getUser();
    this.headers = new HttpHeaders().set('Access-Token', this.user.token);
    this._baseURL = environment.apiUrl;
  }
  _addEditRole(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/add-update/role',
      obj,
      { headers: this.headers }
    );
  }
  _addEditRolePermission(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/add-update/role/use-cases',
      obj,
      { headers: this.headers }
    );
  }
  _assignRole(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/assign/role',
      obj,
      { headers: this.headers }
    );
  }
  _getRolePermission(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/get/role/use-cases',
      obj,
      { headers: this.headers }
    );
  }
  _updateRoleStatus(obj: any): Observable<any> {
    return this.httpClient.put(
      this._baseURL + 'api/role-access/v1/update/role/status',
      obj,
      { headers: this.headers }
    );
  }
  _getRoleList(): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/get/roles',
      {},
      { headers: this.headers }
    );
  }
  _getDefaultRolePermission(): Observable<any> {
    return this.httpClient.get(
      this._baseURL + 'api/role-access/v1/get/use-cases',
      { headers: this.headers }
    );
  }
  _getRoleUserList(): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/get/users',
      {},
      { headers: this.headers }
    );
  }
  _getUserRoleByUserId(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/role-access/v1/get/user/role',
      obj,
      { headers: this.headers }
    );
  }
  _deleteRole(obj: any): Observable<any> {
    return this.httpClient.put(
      this._baseURL + 'api/role-access/v1/update/role/status',
      obj,
      { headers: this.headers }
    );
  }
}

import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { customerFilter, SplitPipe } from '../_pipes/filter.pipe';
import { ReplaceUnderscorePipe } from '../_pipes/replace-underscore-pipe';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SelectAllCheckboxComponent } from './components/select-all-checkbox/select-all-checkbox.component';
import { SelectAllMatCheckboxComponent } from './components/select-all-mat-checkbox/select-all-mat-checkbox.component';
import { SharedRoutingModule } from './shared-routing.module';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    customerFilter,
    SplitPipe,
    ReplaceUnderscorePipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    SelectAllMatCheckboxComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    ReplaceUnderscorePipe,
    SelectAllMatCheckboxComponent,
    customerFilter,
    SplitPipe,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}

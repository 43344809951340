import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { APIResponse } from '../_types/api-responses.types';
import { Notification } from '../_types/notifications.types';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  headers: any;
  _baseURL: any;
  user: any;
  deviceId: number;
  deviceInfo: any;
  constructor(
    private httpClient: HttpClient,
    private globaols: Globals,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceId = Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail: any = {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    };
    this.user = this.globaols.getUser();
    this.headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(devicedetail))
      .set('Access-Token', this.user.token);
    this._baseURL = environment.apiUrl;
  }

  _getTemplateList(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/communicator/v1/newsletter/get/templates',
      obj,
      { headers: this.headers }
    );
  }
  _getTemplateDetails(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/communicator/v1/newsletter/get/template-details',
      obj,
      { headers: this.headers }
    );
  }
  _addTemplate(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/communicator/v1/newsletter/add/template',
      obj,
      { headers: this.headers }
    );
  }
  _sendNewsletter(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/communicator/v1/newsletter/send/newsletter',
      obj,
      { headers: this.headers }
    );
  }
  _getNotificationList(): Observable<
    APIResponse<{ notificationList: Notification[] }>
  > {
    return this.httpClient.get<
      APIResponse<{ notificationList: Notification[] }>
    >(this._baseURL + 'api/communicator/v1/communication/get/notification', {
      headers: this.headers,
    });
  }

  _readNotifications(): Observable<any> {
    return this.httpClient.put(
      this._baseURL + 'api/communicator/v1/communication/read/notification',
      {},
      { headers: this.headers }
    );
  }
}

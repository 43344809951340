<div class="page-not-found">
  <div>
    <figure>
      <img src="assets/images/404.png" alt="page not found" class="img-fluid" />
    </figure>
    <div class="h5 text-uppercase" i18n>oops! page not found</div>
    <p i18n>
      Sorry the page you are looking for doesn't exist.
      <span>Please check the address and try again.</span>
    </p>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers: HttpHeaders;
  _baseURL: any;
  deviceId: any;
  headersLogin: any;
  deviceInfo: any;
  constructor(
    private httpClient: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceId = Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail: any = {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    };
    this.headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(devicedetail));
    this._baseURL = environment.apiUrl;
  }

  getLogin(obj: any, appToken: any = ''): Observable<any> {
    this.headersLogin = new HttpHeaders()
      .set('content-type', 'application/json')
      .set(
        'User_Agent',
        '{"platform" : "website","browser" : "","browserVersion" : "","osVersion" : "", "deviceId" : ' +
          this.deviceId +
          ',"appVersion" : "","ipAddress" : "","macAddress" : ""}'
      )
      .set('APP-Token', appToken);
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signin',
      obj,
      { headers: this.headersLogin }
    );
  }
  signUp(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signup',
      obj,
      { headers: this.headers }
    );
  }
  verifyOtp(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/perform/action',
      obj,
      { headers: this.headers }
    );
  }
  _forgotPassword(obj: any): Observable<any> {
    return this.httpClient.put(
      this._baseURL + 'api/ims/v1/partner/forgot/password',
      obj,
      { headers: this.headers }
    );
  }
  _forgotPasswordVerification(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/verify/forgot/password',
      obj,
      { headers: this.headers }
    );
  }
  _resendOtp(obj: any): Observable<any> {
    let device: any = Date.now() + 'lty';
    let header1 = new HttpHeaders()
      .set('accept', 'application/json')
      .set('data-hash', 'testww1')
      .set('device-id', device)
      .set('Content-Type', 'application/json');
    return this.httpClient.put(
      this._baseURL + 'api/communicator/v1/otp/resend',
      obj,
      { headers: header1 }
    );
  }
  _logout(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signout',
      obj,
      { headers: this.headers.append('Access-Token', obj.token) }
    );
  }
}

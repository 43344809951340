<div class="dropdown lang-dropdown">
  <button
    class="btn btn-secondary dropdown-toggle"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <img src="assets/images/{{ countryFlag }}" alt="" />
  </button>
  <ul class="dropdown-menu">
    <li (click)="languageSwitcherAction('en', 'flag01.png')">
      <a class="dropdown-item" href="javascript:void(0);">
        <span><img i18n-alt src="assets/images/flag01.png" alt="USA" /></span>
        <ng-container i18n>English</ng-container>
      </a>
    </li>
    <li (click)="languageSwitcherAction('fr', 'flag03.png')">
      <a class="dropdown-item" href="javascript:void(0);">
        <span><img i18n-alt src="assets/images/flag03.png" alt="France" /></span
        ><ng-container i18n>French</ng-container>
      </a>
    </li>
  </ul>
</div>

<footer>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="footer-logo text-center">
          <img
            src="../../../../assets/images/footer-logo.png"
            i18n-alt
            alt="LTY"
          />
        </div>
        <div
          class="footer-navbar text-center d-flex d-md-flex justify-content-center"
        >
          <ul class="d-flex justify-content-center">
            <li><a href="javascript:void(0);" i18n>Mobile App</a></li>
            <li><a href="javascript:void(0);" i18n>Features</a></li>
            <li><a href="javascript:void(0);" i18n>About</a></li>
            <li><a href="javascript:void(0);" i18n>Contact</a></li>
            <li><a href="javascript:void(0);" i18n>Privacy</a></li>
            <li><a href="javascript:void(0);" i18n>Help</a></li>
          </ul>
        </div>
        <div class="row g-0 align-items-center">
          <div class="col-md-4">
            <!-- <div class="dropdown lang-dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false" i18n>
                                english
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="javsacript:void(0);" i18n>english</a></li>
                                <li><a class="dropdown-item" href="javsacript:void(0);" i18n>Chinese</a></li>
                            </ul>
                        </div> -->
          </div>
          <div class="col-md-4">
            <div class="footer-social text-center">
              <a href="javascript:void(0);"
                ><i class="lab la-facebook-f"></i
              ></a>
              <a href="javascript:void(0);"><i class="lab la-twitter"></i></a>
              <a href="javascript:void(0);"
                ><i class="lab la-linkedin-in"></i
              ></a>
              <a href="javascript:void(0);"><i class="lab la-instagram"></i></a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="footer-copyright" i18n>
              &copy; LTY 2022, We love our users!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

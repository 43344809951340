import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event), // pass further respone
      catchError((error: HttpErrorResponse) => {
        if (
          error &&
          (error.error.error.errorCode === 'ER-TOKEN-4000' ||
            error.error.error.errorCode === 'ER-IMS-4009')
        ) {
          localStorage.clear();
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        }
        throw error;
      })
    );
  }
}
